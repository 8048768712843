import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from "../api.service";
import {FeedItem} from "../interfaces/FeedItem";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {ScrollService} from "../scroll.service";

@Component({
  selector: 'hn-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss']
})
export class FeedComponent implements OnInit, OnDestroy {

  public feedItems: FeedItem[] = [];
  private category: string = '';
  private page: number = 1;

  private paramsSub;
  private navSub;

  constructor(private api: ApiService, private route: ActivatedRoute, private router: Router, private scrollService: ScrollService) {
  }

  ngOnInit() {
    this.paramsSub = this.route.params
      .subscribe(
        (params) => {
          this.category = params.category || 'news';
          if (params.page) {
            this.page = parseInt(params.page);
          }
        }
      );

    this.navSub = this.router.events.subscribe(
      (evt) => {
        if (evt instanceof NavigationEnd) {
          this.feedItems = [];
          this.loadFeed();
        }
      }
    );

    this.loadFeed();
  }

  loadFeed(): void {
    this.api.getFeed(this.category, this.page)
      .subscribe(
        (res) => {
          this.feedItems = res;
          this.scrollService.feedLoaded.emit();
        }
      );
  }

  get nextPageRoute(): string {
    let page = this.route.snapshot.params['page'];
    return `/${this.category}/${++page || 2}`;
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
    this.navSub.unsubscribe();
  }
}
