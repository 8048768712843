<div class="navbar">
  <a [routerLink]="''" class="logo">
    <div class="icon">
      <div class="y">Y</div>
    </div>
    Hacker&nbsp;News
  </a>
  <div class="categories">
    <a [routerLink]="'newest'">new</a> |
    <!--<a [routerLink]="'newcomments'">comments</a> |-->
    <a [routerLink]="'ask'">ask</a> |
    <a [routerLink]="'show'">show</a> |
    <a [routerLink]="'jobs'">jobs</a>
  </div>
</div>
