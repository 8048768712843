import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {ScrollService} from "./scroll.service";

@Component({
  selector: 'hn-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private previousRoute: string;
  private scrollPos: number = 0;
  private readyForScroll: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private scrollService: ScrollService) {
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart && this.isFeedPage) {
        this.scrollPos = window.scrollY;
      }
      if (evt instanceof NavigationEnd && this.isFeedPage) {
        if (this.previousRoute === evt.url) {
          this.readyForScroll = true;
        }
        this.previousRoute = evt.url;
      }
    });

    this.scrollService.feedLoaded.subscribe(() => {
      if (this.readyForScroll) {
        setTimeout(() => {
          window.scrollTo(0, this.scrollPos);
        }, 0);
        this.readyForScroll = false;
      }
    });
  }

  get isFeedPage(): boolean {
    return this.route.firstChild.component === undefined;
  }
}
