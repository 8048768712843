import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "../api.service";
import { Item } from "../interfaces/Item";

@Component({
  selector: 'hn-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit, OnDestroy {

  @Input('item') item: Item;
  public isRoot: boolean = false;
  public isCollapsed: boolean = false;

  private itemSub;

  constructor(private api: ApiService, private http: HttpClient, private route: ActivatedRoute) { }

  ngOnInit() {
    if (!this.item) {
      this.isRoot = true;
      this.itemSub = this.route.params.subscribe(
        (params) => {
          this.loadItem(params.id);
        }
      )
    }
  }

  loadItem(id) {
    this.item = null;
    this.api.getItem(id)
      .subscribe(
        (res) => {
          this.item = res;
        }
      );
  }

  ngOnDestroy() {
    if (this.itemSub) {
      this.itemSub.unsubscribe();
    }
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

}
