import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {FeedComponent} from './feed/feed.component';
import {HttpClientModule} from "@angular/common/http";
import {FeedItemComponent} from './feed/feed-item/feed-item.component';
import {NavbarComponent} from './navbar/navbar.component';
import {ItemComponent} from './item/item.component';
import {UserComponent} from './user/user.component';
import {TransferHttpCacheModule} from '@nguniversal/common';

@NgModule({
  declarations: [
    AppComponent,
    FeedComponent,
    FeedItemComponent,
    NavbarComponent,
    ItemComponent,
    UserComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    HttpClientModule,
    TransferHttpCacheModule,
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
