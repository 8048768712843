<div class="user">
<table *ngIf="user">
  <tr>
    <td>user:</td>
    <td>{{user?.id}}</td>
  </tr>
  <tr>
    <td>created:</td>
    <td>{{user?.created}}</td>
  </tr>
  <tr>
    <td>karma:</td>
    <td>{{user?.karma}}</td>
  </tr>
  <tr *ngIf="user?.about">
    <td valign="top">about:</td>
    <td [innerHTML]="user.about"></td>
  </tr>
</table>
</div>
