import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { FeedItem } from "./interfaces/FeedItem";
import { Item } from './interfaces/Item';
import { User } from "./interfaces/User";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private baseUrl = 'https://api.hnpwa.com/v0/';

  constructor(private http: HttpClient) {
  }

  getFeed(category: string, page: number): Observable<FeedItem[]> {
    return this.http.get<FeedItem[]>(`${this.baseUrl}${category}/${page}.json`);
  }

  getItem(id: number): Observable<Item> {
    return this.http.get<Item>(`${this.baseUrl}item/${id}.json`);
  }

  getUser(id: number): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}user/${id}.json`);
  }
}
