<hn-feed-item *ngIf="item?.type === 'link'" [feedItem]="item"></hn-feed-item>

<div class="post" [ngClass]="{'border': item?.level > 0}">
  <div class="post-meta" *ngIf="item?.type === 'comment'">
    <a [routerLink]="'/user/' + item?.user" class="underlined">{{item.user}}</a>&nbsp;<a [routerLink]="'/item/' + item?.id" class="underlined">{{item.time_ago}}</a>&nbsp;
    <span class="toggle-collapse" (click)="toggleCollapse()">[&thinsp;<span *ngIf="!isCollapsed">-</span><span *ngIf="isCollapsed">+{{item?.comments_count}}</span>&thinsp;]</span>
  </div>
  <div class="post-content-wrapper" *ngIf="!isCollapsed">
    <div class="post-content" [innerHTML]="item?.content" [ngClass]="{'root': isRoot}"></div>
    <div class="comments" [ngClass]="{'root': isRoot}">
      <div *ngFor="let item of item?.comments" class="child-comment">
        <hn-item [item]="item"></hn-item>
      </div>
    </div>
  </div>
</div>

