<div class="feed-item">
  <div class="title-container">
    <a *ngIf="feedItem.type !== 'ask'" [href]="feedItem.url" class="title" target="_blank" rel="noopener nofollow">{{feedItem.title}}</a>
    <a *ngIf="feedItem.type === 'ask'" [routerLink]="'/item/' + feedItem.id" class="title">{{feedItem.title}}</a>
  </div>
  <div class="meta-container">
    <span class="meta">{{feedItem.points}} points by <a [routerLink]="'/user/' + feedItem.user">{{feedItem.user}}</a> {{feedItem.time_ago}}
      | <a [routerLink]="'/item/' + feedItem.id">{{feedItem.comments_count}} comments</a></span>
  </div>
</div>
