import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeedComponent } from "./feed/feed.component";
import { ItemComponent } from "./item/item.component";
import { UserComponent } from "./user/user.component";

const routes: Routes = [
  {path: 'item/:id', component: ItemComponent},
  {path: 'user/:id', component: UserComponent},
  {
    path: '',
    children: [
      {path: '', component: FeedComponent},
      {path: ':category', component: FeedComponent},
      {path: ':category/:page', component: FeedComponent},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
