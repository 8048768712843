import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from "../api.service";
import { ActivatedRoute } from "@angular/router";
import { User } from "../interfaces/User";

@Component({
  selector: 'hn-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {

  public user: User | null;
  private userSub;

  constructor(private api: ApiService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.userSub = this.api.getUser(this.route.snapshot.params.id)
      .subscribe(
        (res) => {
          this.user = res;
        }
      )
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

}
